import Layout from "@/components/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function ErrorPage() {
  return (
    <Layout title="Page not found">
      <div className="hidden justify-center lg:justify-between h-screen align-middle items-center">
        <div className="lg:relative absolute left-0 z-0 opacity-50 lg:opacity-100">
          <StaticImage
            src="../static/error/hand2.png"
            alt=""
            placeholder="tracedSVG"
            width={500}
            className="max-w-[500px] mt-[50%]"
          />
        </div>
        <div className="flex flex-col justify-center relative z-20">
          <div className="relative max-w-[700px] max-h-[700px] text-center flex flex-col justify-center align-middle items-center">
            <p className="font-[600] relative z-10 text-[30px]">
              OOPS, PAGE NOT FOUND :(
            </p>
            <h1 className="text-[200px] relative my-[4rem] z-10 tracking-[1rem]">
              404
            </h1>
            <div className="">
              <StaticImage
                src="../static/error/ellipse.png"
                alt=""
                placeholder="tracedSVG"
                className="!absolute bottom-[50%] w-[130%] translate-y-[50%] right-[50%] translate-x-[50%] z-0"
              />
            </div>
          </div>
          <Link to="/" className="mx-auto">
            <button className="relative z-20 hover:bg-orange hover:text-white mx-auto h-[4rem] bg-white px-[5rem] text-[22px] font-[600] text-orange md:mt-0">
              BACK
            </button>
          </Link>
        </div>

        <div className="lg:relative absolute right-0 z-0">
          <StaticImage
            src="../static/error/hand1.png"
            alt=""
            placeholder="tracedSVG"
            width={500}
            className="max-w-[500px] opacity-50 lg:opacity-100"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 max-w-[1600px] mx-auto">
        <div>
          <StaticImage
            src="../static/error/hand2.png"
            alt=""
            placeholder="tracedSVG"
            width={500}
            className="max-w-[500px] mt-[50%] opacity-50 lg:opacity-100"
          />
        </div>

        <div className="absolute z-20 top-[50%] lg:-translate-y-[50%] -translate-y-[10%] sm:-translate-y-[30%] right-[50%] translate-x-[50%]">
          <div className="flex flex-col justify-center gap-[10rem] xl:gap-[15rem] relative z-20">
            <div className="relative max-w-[700px] max-h-[700px] text-center flex flex-col justify-center align-middle items-center">
              <h3 className="font-[600] relative z-10 md:text-[30px]">
                OOPS, PAGE NOT FOUND :(
              </h3>
              <h1 className="text-[100px] md:text-[160px] relative my-[4rem] z-10 tracking-[1rem]">
                404
              </h1>
              <div className="">
                <StaticImage
                  src="../static/error/ellipse.png"
                  alt=""
                  placeholder="tracedSVG"
                  className="!absolute bottom-[50%] w-[160%] md:w-[140%] translate-y-[50%] right-[50%] translate-x-[50%] z-0"
                />
              </div>
            </div>
            <Link to="/" className="mx-auto">
              <button className="relative z-20 hover:bg-orange hover:text-white mx-auto h-[4rem] bg-white px-[5rem] text-[22px] font-[600] text-orange md:mt-0">
                BACK
              </button>
            </Link>
          </div>
        </div>

        <div className="flex justify-end">
          <div>
            <StaticImage
              src="../static/error/hand1.png"
              alt=""
              placeholder="tracedSVG"
              width={500}
              className="max-w-[600px] opacity-50 lg:opacity-100"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
